import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import Button from "@mui/material/Button";
import { styled, alpha } from '@mui/material/styles';

export const SignInButton = () => {
    const { instance } = useMsal();
    const handleLogin = () => {
        instance.loginRedirect(loginRequest);
    }

    return (
        <div>
            <Button variant="outlined"
                onClick={() => handleLogin()}> Login
            </Button>
        </div>
    )
};