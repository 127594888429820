import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export default function SearchBar() {
  const navigate = useNavigate();
  const [term, setTerm] = useState([]);
  const [clear, setClear] = useState(false);
  const location = useLocation();
  useEffect(() => {
    setClear(false);
  }, [term]);

  return (
    <>
    
    <Paper sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}>
           
           <InputBase
             sx={{ ml: 1, flex: 1 }}
             placeholder="Search Thoughts"
             inputProps={{ "aria-label": "search thoughts" }}
             value={term}
             onChange={(e) => {
               setTerm(e.target.value);
             }}
           />
           <IconButton
             type="button"
             sx={{ p: "10px" }}
             aria-label="search"
             onClick={() => {
               if (!clear) {
                 if (term.length === 0) {
                   navigate(`${location.pathname}`);
                 } else {
                   setClear(true);
                   navigate(`${location.pathname}?term=${term}`);
                 }
               } else {
                 setClear(false);
                 setTerm([]);
                 navigate(`${location.pathname}`);
               }
             }}
           >
             {clear === true ? <CancelIcon /> : <SearchIcon />}
           </IconButton>
         </Paper>
    
   
    </>
   
    
  );
}
