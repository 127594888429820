import { msalInstance } from "../index";
import { protectedResources } from "../authConfig";

const getToken = async (scopes) => {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error(
      "No active account! Verify a user has been signed in and setActiveAccount has been called."
    );
  }

  const response = await msalInstance.acquireTokenSilent({
    account: account,
    scopes: scopes,
  });

  return response.accessToken;
};

export const getThoughts = async (currentUserId, searchTerm) => {

  const accessToken = await getToken(
    protectedResources.apiThoughts.scopes.read
  );

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };


if(searchTerm !== undefined && searchTerm !== null){
  var apiEndpoint = `https://cmh-uat.azurewebsites.net/api/thought/search/${searchTerm}/user/${currentUserId}`;
  return fetch(apiEndpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}else{
  var apiEndpoint = `https://cmh-uat.azurewebsites.net/api/thought/user/${currentUserId}`;
  return fetch(apiEndpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}


};

export const postThought = async (thought) => {
  const accessToken = await getToken(
    protectedResources.apiThoughts.scopes.write
  );

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(thought),
  };
  var apiEndpoint = `https://cmh-uat.azurewebsites.net/api/thought`;
  return fetch(apiEndpoint, options)
    .then(() => {})
    .catch((error) => console.log(error));
};

export const putThought = async (thought) => {
  const accessToken = await getToken(
    protectedResources.apiThoughts.scopes.write
  );

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "PUT",
    headers: headers,
    body: JSON.stringify(thought),
  };
  var apiEndpoint = `https://cmh-uat.azurewebsites.net/api/thought`;
  return fetch(apiEndpoint, options)
    .then(() => {})
    .catch((error) => console.log(error));
};

export const deleteThought = async (thoughtId) => {
  const accessToken = await getToken(
    protectedResources.apiThoughts.scopes.read
  );
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "DELETE",
    headers: headers,
  };
  var apiEndpoint = `https://cmh-uat.azurewebsites.net/api/thought/${thoughtId}`;
  return fetch(apiEndpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

export const search = async (term, currentUserId) => {
  const accessToken = await getToken(
    protectedResources.apiThoughts.scopes.read
  );

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };
  var apiEndpoint = `https://cmh-uat.azurewebsites.net/api/thought/search/${term}/user/${currentUserId}`;
  return fetch(apiEndpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}
