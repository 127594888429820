//Icons
import DarkModeIcon from '@mui/icons-material/DarkMode';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
export const navBarItems=[
    {
        id:0,
        icon:<EmojiObjectsIcon />,
        label:'Thoughts',
        route:'thoughts'
    },
    {
        id:1,
        icon:<ArchiveIcon />,
        label:'Archive',
        route:'archive'
    },
    {
        id:2,
        icon:<SettingsIcon />,
        label:'Settings',
        route:'settings'
    },
]