import { createTheme } from "@mui/material/styles";

export const siteTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {

        },
      },
    },
  },
  palette: {
    mode: "light",
  },
});
