// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { ThemeProvider } from "@mui/material/styles";
import { siteTheme } from "./siteTheme";
import { useState, useEffect } from "react";
import { createTheme } from "@mui/material/styles";
import CssBaseline from '@mui/material/CssBaseline';
//Application pages
import { Outlet } from "react-router-dom";
import NavBar from "./ui-components/NavBar";
import Box from "@mui/material/Box";
import Thoughts from "./pages/Thoughts";
import './App.css';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { useMsalAuthentication } from "@azure/msal-react";
import SearchBar from "./ui-components/SearchBar";
import { Grid } from "@mui/material";
function App({ pca }) {
  const [darkTheme, setDarkTheme] = useState(false);
  const siteTheme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          contained: {
  
          },
        },
      },
    },
    palette: {
      mode: darkTheme ? "dark" : "light",
    },
  });
  
  return (
    <ThemeProvider theme={siteTheme}>
      <CssBaseline />
      <MsalProvider instance={pca}>
        <Box>
          <NavBar setDarkTheme={setDarkTheme}></NavBar>
        </Box>
        <Box>
          <AuthenticatedTemplate>
           
            <Outlet context={[darkTheme, setDarkTheme]}/>
          </AuthenticatedTemplate>
        </Box>
      </MsalProvider>
    </ThemeProvider>
  );
}

export default App;
