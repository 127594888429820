import React from 'react';
import Grid from '@mui/material/Grid';
import Thoughts from "./Thoughts";
const Archive = () => {
    return (
        <>
        <Thoughts/>
        </>
    );
}

export default Archive;