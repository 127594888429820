import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material/styles';
import {siteTheme} from './siteTheme'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline';
// MSAL imports
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
//Pages
import Thoughts from './pages/Thoughts';
import Archive from './pages/Archive';
import Settings from './pages/Settings';
import { light } from '@mui/material/styles/createPalette';
// import { Home } from './pages/Home';
export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;

    console.log('successfully logged In');
    msalInstance.setActiveAccount(account);
  }
});
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
<ThemeProvider theme={siteTheme}>
<CssBaseline />
<BrowserRouter>
  <Routes>
    <Route path="/" element={<App pca={msalInstance}/>} >
    {/* <Route path="/home" element={<Home/>} /> */}
    <Route path="/thoughts" element={<Thoughts/>} />
    <Route path="/Archive" element={<Archive/>} />
    <Route path="/settings" element={<Settings/>} />
    </Route>
  </Routes>
  </BrowserRouter>
</ThemeProvider>

);
reportWebVitals();
