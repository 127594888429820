import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import SearchBar from "./SearchBar";
import { navBarItems } from "../consts/navBarItems";
import { useNavigate } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import Grid from "@mui/material/Grid";
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import ArchiveIcon from '@mui/icons-material/Archive';
import { useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import {
  Drawer,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from "@mui/material";

import {
  CheckBoxOutlineBlankOutlined,
  DraftsOutlined,
  HomeOutlined,
  InboxOutlined,
  MailOutline,
  ReceiptOutlined,
} from "@mui/icons-material";
import { useState } from "react";
import SignInSignOutButton from "./SignInSignOutButton";

const NavBar = ({setDarkTheme}) => {
  const isAuthenticated = useIsAuthenticated();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  var isArchived = searchParams.get("archive") ? searchParams.get("archive") ==='true' : false;

  const getList = () => (
    <div style={{ width: 250 }} onClick={() => setOpen(false)}>
      <List>
      {navBarItems.map((navItem, index) => (
        <ListItem key={index}>
          <ListItemButton
            onClick={() => {
              navigate(navItem.label);
            }}
          >
            <ListItemIcon>{navItem.icon}</ListItemIcon>
            <ListItemText primary={navItem.label} />
          </ListItemButton>
        </ListItem>
      ))}
      </List>
     
    </div>
  );
  return (
    <AppBar position="static">
      <Toolbar sx={{bgcolor:'#4D2D61'}}>
        <Grid container spacing={2} alignItems='center' justifyContent='space-between'>
          <Grid item xs={2}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => isAuthenticated ? setOpen(true):null}
            >
              <MenuIcon />
            </IconButton> 
          </Grid>
          {/* <Grid item xs={6}>
            { isAuthenticated ? <SearchBar/>: null}
          </Grid> */}
          <Grid item xs={2}>
            <SignInSignOutButton />
            
          </Grid>
        </Grid>
      </Toolbar>
      <Drawer open={open} anchor={"left"} onClick={() => setOpen(false)}>

      <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent:'center',
        '& > :not(style)': {
          m: 1,
         
        },
      }}
    >
    
    </Box>
      
    <List>
    <ListItem>
    <ListItemButton
            onClick={() => {
              navigate(`/thoughts`);
            }}
          >
            <ListItemIcon><EmojiObjectsIcon /></ListItemIcon> 
            <ListItemText primary="Thoughts" />
          </ListItemButton>
    </ListItem>
    <ListItem>
    <ListItemButton
            onClick={() => {
              navigate(`/archive`);
            }}
          >
            <ListItemIcon><ArchiveIcon /></ListItemIcon>
            <ListItemText primary="Archive" />
          </ListItemButton>
    </ListItem>
    <ListItem>
    <ListItemButton
            onClick={() => {
              navigate(`/settings`);
            }}
          >
            <ListItemIcon><ArchiveIcon /></ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItemButton>
    </ListItem>
    
    </List>
        {/* {getList()} */}

        
        <Box>
        {/* <Typography align="center" variant="caption"> Designed and developed by </Typography>
        <Typography align="center" variant="caption"> Leosoft</Typography>
        <Typography align="center" variant="caption"> V 1.0.0 </Typography> */}
        </Box>

        <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent:'center',
        '& > :not(style)': {
          m: 1,
          width: 70,
          height: 55,
        },
      }}
    >
    </Box>
      </Drawer>
     
    </AppBar>
  );
};

export default NavBar;
