import React from "react";
import Grid from "@mui/material/Grid";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import { useOutletContext } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { minHeight } from "@mui/system";
const Settings = () => {
  const [darkTheme, setDarkTheme] = useOutletContext();
  const label = { inputProps: { "aria-label": "Color switch demo" } };
  const handleChange = () => {
    setDarkTheme(!darkTheme);
  };
  return (
    <>
      <Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            marginTop: 5,
          }}
        >
          <Paper variant="outlined">
            <Card
              sx={{
                minWidth: 300,
                minHeight: 300,
              }}
            >
              <CardHeader title="Hello!"></CardHeader>
              <CardContent>
                <FormControl component="fieldset">
                  <FormControlLabel
                    value="Dark theme"
                    control={
                      <Switch
                        {...label}
                        checked={darkTheme}
                        onChange={handleChange}
                      />
                    }
                    label="Dark theme"
                    labelPlacement="start"
                  />
                </FormControl>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            marginTop: 5,
          }}
        >
          <Paper variant="outlined">
            <Card
              sx={{
                minWidth: 300,
                minHeight: 300,
              }}
            >
              <CardHeader title="Settings"></CardHeader>
              <CardContent>
                <FormControl component="fieldset">
                  <FormControlLabel
                    value="Dark theme"
                    control={
                      <Switch
                        {...label}
                        checked={darkTheme}
                        onChange={handleChange}
                      />
                    }
                    label="Dark theme"
                    labelPlacement="start"
                  />
                </FormControl>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        ></Grid>
      </Grid>
    </>
  );
};

export default Settings;
