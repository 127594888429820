import React, { useState, useEffect } from "react";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Stack } from "@mui/system";
import Box from "@mui/material/Box";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { pink } from "@mui/material/colors";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ArchiveIcon from "@mui/icons-material/ArchiveOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import CancelIcon from "@mui/icons-material/CancelOutlined";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActiveOutlined";
import TagIcon from "@mui/icons-material/TagOutlined";
import { msalInstance } from "../index";
import DateFnsAdapter from "@date-io/date-fns";
import { format } from "date-fns";
import UnarchiveIcon from '@mui/icons-material/UnarchiveOutlined';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function ThoughtDetails({
  showThoughtForm,
  selectedThought,
  editing,
  updateThoughts,
  removeThought,
  saveThought,
}) {
  const account = msalInstance.getActiveAccount();
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [tags, setTags] = useState(["New", "Refine", "Action", "Done"]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [showReminder, setShowReminder] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [id, setId] = useState(null);
  const [created, setCreated] = useState(null);

  const clearForm = () => {
    setSelectedDateTime(null);
    setTitle("");
    setDescription("");
    setShowReminder(false);
    setShowTags(false);
    setSelectedTags([]);
    setId(null);
    setCreated(null);
  };

  useEffect(() => {
    if (selectedThought != null) {
      setId(selectedThought?.id);
      setTitle(selectedThought?.title);
      setDescription(selectedThought?.description);
      setSelectedDateTime(selectedThought?.reminder);
      if (selectedThought?.reminder) {
        setShowReminder(true);
      }
      setSelectedTags(selectedThought?.tags ? selectedThought.tags : []);
      setShowTags(true);
      setCreated(selectedThought?.created);
    }
  }, [selectedThought, selectedThought?.readTime]);

  var thought: ThoughtModel = {
    title: title,
    description: description,
    userId: account.idTokenClaims.sub,
    tags: selectedTags.length > 0 ? selectedTags : ["New"],
    reminder: selectedDateTime,
    created: created,
    id: id,
    archived: selectedThought?.archived
  };
  const handleChange = (event: SelectChangeEvent<typeof selectedTags>) => {
    const {
      target: { value },
    } = event;

    setSelectedTags(typeof value === "string" ? value.split(",") : value);
  };

  const save = async () => {
    if (editing) {
      await updateThoughts(thought);
    } else {
      await saveThought(thought);
    }
  };

  var ReminderComponent = (
    <>
      <LocalizationProvider dateAdapter={DateFnsAdapter}>
        <DateTimePicker
          renderInput={(params) => <TextField {...params} variant="standard" />}
          value={selectedDateTime}
          onChange={(newValue) => {
            setSelectedDateTime(newValue);
          }}
          color="primary"
        />
      </LocalizationProvider>
    </>
  );

  var TagsComponent = (
    <>
      <FormControl sx={{ m: 1, width: 300 }}>
        <Select
          multiple
          value={selectedTags}
          onChange={handleChange}
          variant="filled"
          input={
            <InputBase
              id="select-multiple-chip"
              label="Chip"
              variant="filled"
            />
          }
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {tags.map((name) => (
            <MenuItem
              key={name}
              value={name}
              // style={getStyles(name, personName, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );

  var DeleteComponent = (
    <>
      <Tooltip title="Remove">
        <IconButton
          size="small"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={() => {
            showThoughtForm();
            removeThought(id);
            clearForm();
          }}
        >
          <DeleteIcon sx={{ color: pink[500] }} />
        </IconButton>
      </Tooltip>
    </>
  );

  var ArchiveComponent = (
    <>
    <Tooltip title="Archive">
      <IconButton
        size="small"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{ mr: 2 }}
        onClick={() => {
          thought.archived = true;
          updateThoughts(thought);
          showThoughtForm();
          clearForm();
        }}
      >
        <ArchiveIcon/>
      </IconButton>
      </Tooltip>
    </>
  );
  
  var UnArchiveComponent =(
    <>
     <Tooltip title="Unarchive">
    <IconButton
      size="small"
      edge="start"
      color="inherit"
      aria-label="menu"
      sx={{ mr: 2 }}
      onClick={() => {
        thought.archived = false;
        updateThoughts(thought);
        showThoughtForm();
        clearForm();
      }}
    >
      <UnarchiveIcon/>
    </IconButton>
    </Tooltip>
  </>
  );


  return (
    <Box sx={{ minWidth: 300 }}>
      <Card variant="outlined">
        <CardContent>
          <Stack spacing={2}>
            <InputBase  sx={{ fontSize: 16 , fontWeight: 'bold'}}
              value={title}
              multiline
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              placeholder="Title"
            />
            <InputBase
              sx={{ fontSize: 14 }}
              placeholder="Description"
              multiline
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
            {showReminder ? ReminderComponent : null}
            {showTags ? TagsComponent : null}
            <Typography variant="caption">
              {editing
                ? "Created on " + format(new Date(created), "MM/dd/yyyy kk:mm")
                : ""}
            </Typography>
          </Stack>
        </CardContent>
        <CardActions
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
          }}
        >
          {editing ? DeleteComponent : null}
          {editing === true ? (selectedThought.archived === true ? UnArchiveComponent : ArchiveComponent) : null}
          <Tooltip title="Tag">
          <IconButton
            size="small"
            edge="end"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => {
              setShowTags(!showTags);
            }}
          >
            <TagIcon/>
          </IconButton>
          </Tooltip>
          <Tooltip title="Reminder">
          <IconButton
            size="small"
            edge="end"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => {
              setShowReminder(!showReminder);
            }}
          >
            <NotificationsActiveIcon/>
          </IconButton>
          </Tooltip>
          <Tooltip title="Save & Close">
          <IconButton
            size="small"
            edge="end"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 1 }}
            onClick={() => {
              if (title !== '') {
                showThoughtForm();
                save();
                clearForm();
              } else {
                showThoughtForm();
                clearForm();
              }
            }}
          >
            <CancelIcon/>
          </IconButton>
          </Tooltip>
        </CardActions>
      </Card>
    </Box>
  );
}
