import React from "react";
import { useState, useEffect } from "react";
import { Box, CardHeader, Dialog, Grid } from "@mui/material";
import { Container } from "@mui/system";
import ThoughtsGrid from "../ui-components/ThoughtsGrid";
import Backdrop from "@mui/material/Backdrop";
import ThoughtDetails from "../ui-components/ThoughtDetails";
import { Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useSearchParams, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Masonry from "react-masonry-css";

import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import ArchiveIcon from "@mui/icons-material/ArchiveOutlined";
import UnarchiveIcon from "@mui/icons-material/UnarchiveOutlined";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import { format } from "date-fns";
import { Stack } from "@mui/system";
import {
  getThoughts,
  putThought,
  postThought,
  deleteThought,
} from "../Utils/ThougtsService";
import { msalInstance } from "../index";
import LinearProgress from "@mui/material/LinearProgress";
import SearchBar from "../ui-components/SearchBar";
import { Girl } from "@mui/icons-material";
import Snackbar from "@mui/material/Snackbar";

const Thoughts = () => {
  const location = useLocation();
  console.log(location);
  const isAuthenticated = useIsAuthenticated();
  const [searchParams, setSearchParams] = useSearchParams();
  var isArchive = location.pathname === "/archive" ? true : false;
  const account = msalInstance.getActiveAccount();
  const [open, setOpen] = React.useState(false);
  const [showSnackBar, setShowSnackBar] = React.useState(false);
  const [snackBarMsg, setSnackBarMsg] = React.useState("dsdsds");
  const [thoughtsResponse, setThoughtsResponse] = useState([]);
  const [selectedThought, setSelectedThough] = useState();
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const showThoughtForm = (editing) => {
    setEditing(editing);
    setOpen(!open);
  };

  const assignSelectedThought = (thought) => {
    thought.readTime = new Date();
    setSelectedThough(null);
    setSelectedThough(thought);
    showThoughtForm(true);
  };
  const searchThoughts = async (term) => {
    if (isAuthenticated) {
      await searchThoughts(term).then((response) => {
        setThoughtsResponse(response);
      });
    }
  };

  const updateThoughts = async (thought) => {
    if (isAuthenticated) {
      var editedIndex = thoughtsResponse.findIndex((x) => x.id === thought.id);
      if (thought.archived !== true) {
        thoughtsResponse[editedIndex] = thought;
      }
      await putThought(thought).then(() => {
        getThoughts(account.idTokenClaims.sub).then((response) =>
          setThoughtsResponse(response)
        );
      });
    }
  };

  const removeThought = async (thoughtId) => {
    if (isAuthenticated) {
      var deletedIndex = thoughtsResponse.findIndex((x) => x.id === thoughtId);
      thoughtsResponse.splice(deletedIndex, 1);
      await deleteThought(thoughtId).then(() => {});
      getThoughts(account.idTokenClaims.sub).then((response) =>
        setThoughtsResponse(response)
      );
    }
    setSnackBarMsg("Thought deleted");
    setShowSnackBar(true);
  };

  const saveThought = async (thought) => {
    if (isAuthenticated) {
      thoughtsResponse.push(thought);
      await postThought(thought).then(() => {
        setLoading(true);
        getThoughts(account.idTokenClaims.sub).then((response) => {
          setThoughtsResponse(response);
          setLoading(false);
        });
      });
    }
  };
  const handleSnackBarClose = () => {
    setShowSnackBar(false);
  };
  useEffect(() => {
    if (isAuthenticated) {
      setLoading(true);
      getThoughts(account.idTokenClaims.sub, searchParams.get("term")).then(
        (response) => {
          setThoughtsResponse(response);

          setLoading(false);
        }
      );
    }
  }, [searchParams.get("term")]);
  var loadingComponent = (
    <>
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    </>
  );

  const breakpointColumnsObj = {
    default: 4,
    800: 2,
  };
  return (
    <>
      {loading ? loadingComponent : null}
      <Grid
        container
        alignItems={"center"}
        justifyContent={"center"}
        spacing={2}
        sx={{ marginTop: 3 }}
      >
        <Grid item>
          <Fab
            size="medium"
            onClick={() => {
              showThoughtForm(false);
            }}
          >
            <AddIcon />
          </Fab>
        </Grid>
        <Grid item xs={6}>
          <SearchBar />
        </Grid>
        
      </Grid>

      <Container sx={{ justifyContent: "center" }}>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {thoughtsResponse.map((thought, i) => (
            <div>
              <Box>
                <Card variant="outlined" sx={{ borderRadius: 2 }}>
                  <CardHeader title={thought.title.substring(0, 70)} />
                  <CardContent>
                    <Stack spacing={2}>
                      <Stack>
                        <Typography variant="body2">
                          {thought.description}
                        </Typography>
                      </Stack>

                      <Stack>
                        <Typography variant="caption">
                          {`Created on: ${format(
                            new Date(thought.created),
                            "MM/dd/yyyy kk:mm"
                          )}`}
                        </Typography>
                      </Stack>
                      <Stack>
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {thought.tags.map((value) => (
                            <Chip
                              key={value}
                              label={value}
                              size="small"
                              variant="outlined"
                            />
                          ))}
                        </Box>
                      </Stack>
                    </Stack>
                  </CardContent>
                  <CardActions
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "end",
                    }}
                  >
                    <Tooltip title="Edit">
                      <IconButton
                        size="small"
                        edge="end"
                        color="inherit"
                        sx={{ mr: 2 }}
                        onClick={() => {
                          assignSelectedThought(thought);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton
                        size="small"
                        edge="end"
                        color="inherit"
                        sx={{ mr: 2 }}
                        onClick={() => {
                          removeThought(thought.id);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={thought.archived ? "Unarchive" : "Archive"}>
                      <IconButton
                        size="small"
                        edge="end"
                        color="inherit"
                        sx={{ mr: 2 }}
                        onClick={() => {
                          thought.archived = !thought.archived;
                          updateThoughts(thought);
                        }}
                      >
                        {thought.archived === false ? (
                          <ArchiveIcon />
                        ) : (
                          <UnarchiveIcon />
                        )}
                      </IconButton>
                    </Tooltip>
                  </CardActions>
                </Card>
              </Box>
            </div>
          ))}
        </Masonry>
      </Container>

      <Dialog open={open}>
        <ThoughtDetails
          showThoughtForm={showThoughtForm}
          selectedThought={selectedThought}
          editing={editing}
          updateThoughts={updateThoughts}
          removeThought={removeThought}
          saveThought={saveThought}
        ></ThoughtDetails>
      </Dialog>
      <Snackbar
        open={showSnackBar}
        autoHideDuration={6000}
        onClose={handleSnackBarClose}
        message={snackBarMsg}
        // action={action}
      />

      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        disableScrollLock
      >
       
      </Backdrop> */}
    </>
  );
};

export default Thoughts;
